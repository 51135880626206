<template>
	<div class="c-empty-basket">
		<figure class="c-empty-basket__icon">
			<DsIcon icon-name="shopping-basket" size="xl" />
		</figure>

		<DsText
			html-element="span"
			text-type="body-lg"
			class="c-empty-basket__message"
		>
			{{ labels.emptyBasketText }}
		</DsText>

		<DsButtonGroup
			v-if="!myProductsCtaIsEnabled"
			class="c-empty-basket__button-group"
			desktopAlignment="center"
			desktopDirection="column"
			mobileDirection="column"
			mobileAlignment="stretch"
		>
			<DsButton
				variant="primary"
				:href="emptyBasketUrlHref"
				:title="labels.emptyBasketLinkText"
			>
				{{ labels.emptyBasketLinkText }}
			</DsButton>
		</DsButtonGroup>

		<DsButtonGroup
			v-if="myProductsCtaIsEnabled"
			class="c-empty-basket__button-group"
			desktopAlignment="stretch"
			desktopDirection="column"
			mobileDirection="column"
			mobileAlignment="stretch"
		>
			<DsButton
				v-if="
					myProductsCtaLinkText !== '' && myProductsCtaLinkUrl !== ''
				"
				variant="primary"
				:href="myProductsCtaLinkUrl"
			>
				{{ myProductsCtaLinkText }}
			</DsButton>
			<DsButton variant="secondary-neutral" :href="emptyBasketUrlHref">
				{{ labels.emptyBasketLinkText }}
			</DsButton>
		</DsButtonGroup>
	</div>
</template>

<script>
import {
	DsButtonGroup,
	DsButton,
	DsIcon,
	DsText,
} from '@coloplast/design-system';

export default {
	name: 'basket-empty-state',
	props: {
		labels: {
			type: Object,
			required: true,
		},
		emptyBasketUrl: {
			type: String,
			default: null,
		},
	},
	inject: {
		myProductsCtaLinkText: {
			default: '',
		},
		myProductsCtaLinkUrl: {
			default: '',
		},
		myProductsCtaIsEnabled: {
			default: false,
		},
	},
	components: {
		DsIcon,
		DsButton,
		DsButtonGroup,
		DsText,
	},
	computed: {
		emptyBasketUrlHref() {
			return this.emptyBasketUrl ? this.emptyBasketUrl : '/';
		},
	},
};
</script>

<style scoped lang="scss">
@import 'multisite/styles/settings/settings';
@import 'multisite/styles/tools/tools';

.c-empty-basket {
	--button-group-max-width: 100%;
	--button-group-margin-block-start: #{ds-space(12)};

	align-items: center;
	display: flex;
	flex-direction: column;

	@media (min-width: ds-breakpoint('sm')) {
		--button-group-max-width: max-content;
		--button-group-margin-block-start: #{ds-space(13)};
	}

	&__icon {
		align-items: center;
		align-self: center;
		background-color: ds-color('bg', 'interactive-subtle');
		border-radius: 52px;
		color: ds-color('fg', 'brand');
		display: flex;
		height: 104px;
		margin: 0;
		justify-content: center;
		width: 104px;
	}

	&__message {
		margin: ds-space(8) 0 0;
	}

	&__button-group {
		gap: ds-gap(6);
		margin-block-start: var(--button-group-margin-block-start);
		max-width: var(--button-group-max-width);
		width: 100%;
	}
}
</style>
