import { createApp } from "vue";
import store from 'shared/store';
import FlyOutBasket from './flyOutBasket.vue';

import './styles/main.scss';

const flyOutBasketMount = document.getElementById('fly-out-basket-mount');
if (flyOutBasketMount) {
	const app = createApp({
		name: 'FlyOutBasketApp'
	});
	app.use(store);
	app.component('fly-out-basket', FlyOutBasket);
	app.mount(flyOutBasketMount);
}