export function hideChat() {
	// Zendesk
	if (window.$zopim) {
		window.$zopim.livechat.button.hide();
	}
}

export function showChat() {
	// Zendesk
	if (window.$zopim) {
		window.$zopim.livechat.button.show();
	}
}
