<template>
    <div class="c-upsell-section" v-if="items.length > 0">
		<DsText html-element="h1" text-type="heading-2xl" text-style="bold"  class="c-upsell-section__title">{{ labels.SectionHeading }}</DsText>
        <div v-if="!loading && !error">
            <ul class="c-upsell-section__list">
                <upsell-section-item
                    v-for="(item, index) in items"
                    :key="item.code"
                    :item="item"
                    :model-value="selected"
                    @update:model-value="selected = $event"
                    :index="index"
                    :register-with-observer="registerWithObserver"
                    v-show="isVisible(index)"
                >
                </upsell-section-item>
            </ul>
			<DsButtonGroup desktop-alignment="stretch" class="c-upsell-section__expand">
				<DsButton @click.prevent="toggleExpand" v-if="items.length > limit" variant="ghost">
					<DsText v-if="expanded" html-element="span" text-style="bold">{{ labels.LessProductsLabel }}<DsIcon icon-name="chevron-up" size="sm"/></DsText>
                	<DsText v-else html-element="span" text-style="bold">{{ labels.MoreProductsLabel }}<DsIcon icon-name="chevron-down" size="sm"/></DsText>
				</DsButton>
				<DsButton v-if="selected.length > 0" @click.prevent="addItemsToBasket" variant="primary" size="lg">{{ labels.AddMyProductsLabel }}</DsButton>
			</DsButtonGroup>
        </div>
    </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations} from 'vuex';
import intersectionObserverMixin from 'shared/mixins/intersectionObserverMixin';
import { itemListIdAndName } from 'shared/settings/constants';
import UpsellSectionItem from './upsellSectionItem.vue';
import { track_view_item_list } from 'shared/services/tracking/productTrackingService';
import { track_add_to_cart } from 'shared/services/tracking/basketTrackingService';
import { DsButton, DsButtonGroup, DsIcon, DsText } from '@coloplast/design-system';

export default {
    name: 'upsell-section',
    emits: [
        'upsell-select'
    ],
    components: {
        'upsell-section-item': UpsellSectionItem,
		DsButton,
		DsButtonGroup,
		DsIcon,
		DsText,
    },
    props: {
        labels: {
            type: Object,
            required: true
        },
    },
	mixins: [
		intersectionObserverMixin,
	],
    data() {
        return {
            limit: 2,
            expanded: false,
            selected: [],
            loading: false,
            error: null
        };
    },
    created() {
		this.intializeObserver(this.onProductsObserved, {
			threshold: 0.6,
		});
    },
	async mounted() {
		this.loading = true;
 		await this.fetchMyNonRegularProducts();
        this.loading = false;
        this.$nextTick(() => {
            this.$refs.products?.forEach(product => {
                this.registerWithObserver(product);
            })
        });
	},
    computed: {
        ...mapGetters({
            myNonRegularProductsData: 'getMyNonRegularProductsData',
            basket: 'getBasket',
        }),
        items() {
            return this.myNonRegularProductsData?.nonRegularMyProducts?.filter(nonRegularProduct =>
                !this.basket?.paid?.some(paidProduct =>
                    paidProduct.code === nonRegularProduct.code
                )
            ) || [];
        },
    },
    watch: {
		selected: {
			deep: true,
			handler(newValue) {
				this.$emit('upsell-select', newValue.length > 0);
			},
		},
		items: {
			deep: true,
			handler(newValue) {
				if(newValue.length < 1) return;
				this.$nextTick(() => {
					this.$refs.products?.forEach(product => {
						this.registerWithObserver(product);
					})
				});
			},
		},
    },
    methods: {
        ...mapActions([
            'fetchMyNonRegularProducts',
            'addNonRegularProductsToBasket',
            'fetchBasket',
        ]),
        ...mapMutations([
            'setBasket',
        ]),
		isVisible(index) {
			return this.expanded || index < this.limit;
		},
        toggleExpand () {
            this.expanded = !this.expanded;
        },
        removeAllSelectedItems () {
            this.selected = [];
        },
		async onProductsObserved(productElements) {
			let observedProducts = [];

			productElements.forEach(({ target, isIntersecting }) => {
				if (!isIntersecting) {
					return;
				}
				// call the unregisterFromObserver method that comes from the observer mixin
				this.unregisterFromObserver(target);
				const i = Number(target.dataset.index);
				const product = this.items[i];
				product.index = i;
				observedProducts.push(product);
			})
			if (observedProducts.length > 0) {
				// ga4 EEC tracking view_item_list for products only on viewport
				track_view_item_list({
					productsDto: this.myNonRegularProductsData,
					observedProducts,
					itemListIdAndName: itemListIdAndName.SOMETIMES_PRODUCT
				});
			}
		},
        async addItemsToBasket() {
            const res = await this.addNonRegularProductsToBasket(this.selected);
            this.setBasket(res);
            track_add_to_cart(res);

            this.removeAllSelectedItems();
            this.fetchBasket();
        }
    }
}
</script>
