<template>
	<li
		class="c-upsell-section-item"
		:data-index="index"
		ref="product"
		data-testid="stproduct"
	>
		<label class="c-upsell-section-item__label">
			<input class="c-upsell-section-item__input" type="checkbox" :value="item" v-model="localSelected" />
			<span class="c-upsell-section-item__check"></span>
			<figure class="c-upsell-section-item__product-figure">
				<img v-if="item.imageUrl" class="c-upsell-section-item__product-image" :src="item.imageUrl" :alt="item.displayProductName" />
				<DsIcon v-else icon-name="camera" />
			</figure>
			<div class="c-upsell-section-item__product-header">
				<DsText html-element="span" text-type="body-xs" class="c-upsell-section-item__product-manufacturer">{{item.manufacturer}}</DsText>
				<DsText html-element="h3" text-type="heading-md" text-style="bold" class="c-upsell-section-item__product-title">{{ item.name }}</DsText>
			</div>
		</label>
	</li>
</template>

<script>
import { DsIcon, DsText } from '@coloplast/design-system';

export default {
	name: 'upsell-section-item',
	emits: [
		'update:modelValue',
	],
	props: {
		item: {
			required: true,
			type: Object,
		},
		index: {
			required: true,
			type: Number,
		},
		registerWithObserver: {
			type: Function,
			default: () => {},
		},
		selected: {
			type: Array,
			default: () => [],
		},
		modelValue: {
			type: Array,
			default: () => [],
		},
	},
	components: {
			DsIcon,
			DsText,
	},
	computed: {
		localSelected: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit('update:modelValue', newValue);
			},
		}
	},
	mounted() {
		this.registerWithObserver(this.$el);
	}
}
</script>
