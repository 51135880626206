<template>
	<section v-if="showStickySummary" class="c-sticky-summary" data-testid="basket-sticky-summary">
		<div class="c-sticky-summary__call-to-actions" :class="{ '--alignedright' : hideAllPrices }">
			<div v-if="!hideAllPrices">
				<DsText v-if="!hideTotalPrice" html-element="div" text-type="body-xs">
					<template v-if="e1TaxCalculate.isNeedE1TaxCalculate && e1TaxCalculate.taxParams.isTaxIncluded">
						{{ labels.totalInclVat }}
					</template>
					<template v-else>
						{{ labels.totalLabel }}
					</template>
				</DsText>
				<DsText v-if="!hideTotalPrice" class="c-sticky-summary__total" html-element="span" text-type="body-lg" data-testid="formattedTotalPrice">
					<template v-if="e1TaxCalculate.isNeedE1TaxCalculate">
						<template v-if="e1TaxCalculate.isE1TaxLoading">
							<div class="ds-spinner-animation c-global-basket__tax-spinner">
								<svg
									width="56"
									height="56"
									viewBox="0 0 56 56"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										class="ds-spinner-animation__track"
										x="3"
										y="3"
										width="50"
										height="50"
										rx="25"
										stroke-width="6"
									/>
									<path
										class="ds-spinner-animation__snake"
										d="M4.50674 36.5508C2.95033 37.1173 1.21245 36.3169 0.815599 34.7089C-0.175426 30.6933 -0.26744 26.4951 0.562107 22.4177C1.59161 17.3575 4.00037 12.6806 7.52209 8.90405C11.0438 5.12747 15.5413 2.39833 20.5173 1.01835C24.5269 -0.0935997 28.7213 -0.294665 32.7962 0.413827C34.428 0.697543 35.3477 2.37535 34.8912 3.96749V3.96749C34.4347 5.55962 32.7738 6.45833 31.1344 6.22238C28.1328 5.79039 25.0632 5.98204 22.1202 6.79819C18.2101 7.88256 14.6761 10.0271 11.9087 12.9947C9.14142 15.9623 7.24865 19.6373 6.43968 23.6135C5.83081 26.6062 5.85375 29.6818 6.49408 32.6459C6.84381 34.2648 6.06315 35.9844 4.50674 36.5508V36.5508Z"
									/>
								</svg>
							</div>
						</template>
						<template v-else>
							{{ e1TotalInclTax }}
						</template>
					</template>
					<template v-else>
						{{ basket.formattedTotal }}
					</template>
				</DsText>
			</div>
			<div>
				<DsButton
				    @click="goToCheckout"
					:disabled="basket.isEmpty"
					:class="{ disableGoToCheckout: 'c-btn__disable-on-loading' }"
					variant="primary" size="lg">{{ labels.checkout }}</DsButton>
			</div>
		</div>

		<div
			v-if="!hideAllPrices"
			class="c-sticky-summary__drawer-container"
			data-testid="drawerContainer"
			:class="isOpen ? 'open' : ''">
			<button
				class="c-sticky-summary__drawer-button"
				@click="toggleDrawer"
				data-testid="drawerToggle"
				:aria-label="labels.accessibility.toggleBasketSummary"
				aria-controls="basket-summary"
				:aria-expanded="isOpen ? true : false">
				<DsIcon icon-name="chevron-up" :class="isOpen ? 'open' : ''" />
			</button>

			<div class="c-sticky-summary__drawer" id="basket-summary">
				<fly-out-basket-total
					:labels="labels"
					:show-total="false"
					ref="flyOutBasketTotal"
				/>
				<notification
					v-if="isShowE1Message"
					iconName="information-circle"
					:message="labels.e1FixedTaxRateInformationalMessage"
					data-testid="e1FixedTaxRate"
				/>

				<template v-if="e1TaxCalculate.isNeedE1TaxCalculate">
					<notification
						:message="e1TaxCalculate.taxParams.isPostCodeUnknown ? labels.notAdjustedVatMessage : labels.adjustedVatMessage"
						:iconName="!e1TaxCalculate.taxParams.isPostCodeUnknown ? 'checkmark' : 'information-circle'"
						v-if="e1TaxCalculate.taxParams?.isPostCodeUnknown != null"
						data-testid="vatMessage" />
				</template>
				<template v-else>
					<notification
						:message="labels.vatMessage"
						:iconName="!basket.isTaxValidated ? 'checkmark' : 'information-circle'"
						v-if="basket.marketSpecificTax"
						data-testid="vatMessage" />
				</template>

				<notification
					:message="labels.payLaterNotificationText"
					v-if="showPayFastBlock"
					data-testid="payFastMessage" />
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { track_begin_checkout } from 'shared/services/tracking/basketTrackingService';
import notification from 'shared/components/basket/notification.vue';
import FlyOutBasketTotal from 'shared/components/basket/flyOutBasketTotal.vue';
import { DsButton, DsIcon, DsText } from '@coloplast/design-system';

export default {
	name: 'sticky-summary',
	components: {
		notification,
		FlyOutBasketTotal,
		DsButton,
		DsIcon,
		DsText,
	},
	props: {
		labels: {
			type: Object,
			required: true,
		},
		hideTotal: {
			type: Boolean,
			default: false,
		},
		checkoutUrl: {
			type: String,
			required: true,
		},
		hideAllPrices: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: false,
			disableGoToCheckout: false,
		};
	},
	computed: {
		...mapGetters({
			basket: 'getBasket',
			basketItemsCount: 'getBasketItemCount',
			e1TaxCalculate: "getE1TaxCalculate",
		}),
		showPayFastBlock() {
			return (
				this.labels.payLaterNotificationText && this.basket?.paid?.length > 0
			);
		},
		hideTotalPrice() {
			return this.basket.isReimbursableOnly && this.hideTotal;
		},
		showStickySummary() {
			return this.basket && this.basketItemsCount > 0;
		},
		e1TotalInclTax() {
			return this.e1TaxCalculate.taxParams.price || this.basket.formattedTotal
		},
		isShowE1Message() {
			return this.e1TaxCalculate.taxParams.showE1FixedTaxRateNotification && !this.hasOnlySamplesLineItems && this.numberOfItems > 0
		},
		hasOnlySamplesLineItems() {
			return this.basket && this.basket?.samples?.length > 0 && this.basket?.paid?.length == 0;
		},
		numberOfItems () {
			const allItems = this.basket.paid || [];
			const paid = allItems.filter((item) => !item.isComplimentary);
			return paid.length;
		}

	},
	methods: {
		...mapActions([
			'showSpinner',
		]),
		goToCheckout() {
			this.showSpinner();
			if (this.disableGoToCheckout) return;
			track_begin_checkout(this.basket);
			this.disableGoToCheckout = true;
			window.location.assign(this.checkoutUrl);
		},
		toggleDrawer() {
			this.isOpen = !this.isOpen;
		},
	},


	watch: {
		basket: {
			handler() {
				if ( this.e1TaxCalculate.isNeedE1TaxCalculate) {
					this.$refs?.flyOutBasketTotal?.updateE1TaxPrice();
				}
			},
			deep: true,
		},
	}
};
</script>

<style>
.c-sticky-summary__drawer-button:focus-visible {
	outline: 4px solid var(--ds-color-focus-default);
	border-radius: 4px;
}
</style>
