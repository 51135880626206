<template>
	<div :role="active ? 'dialog' : null" id="flyOutBasket" data-testid="flyOutBasket" ref="root" :aria-label="labels.headline">
		<transition name="fadein">
			<div
				v-if="active"
				class="c-fly-out-basket__background"
				@click.prevent="close"></div>
		</transition>
		<transition name="flyout" @afterLeave="restoreFocus">
			<div v-if="active" class="c-fly-out-basket" :class="{'empty': basketIsEmpty}">
				<div class="c-fly-out-basket__wrapper">
					<DsIconButton class="c-fly-out-basket__close-btn"
								data-testid="flyoutCloseButton"
								@click.prevent="close"
								tabindex="0"
								@keydown.enter.prevent="close"
								icon-name="close"
								variant="tertiary"
								size="sm"
								:aria-label="vm.BaseViewModel.AccessibilityCloseBasketLabel" />
					<global-basket :vm="vm"
								:labels="labels"
								:empty-basket-url="emptyBasketUrl"
								:browse-more-url="browseMoreUrl"
								:min-quantity="vm.BaseViewModel.MinGlobalBasketQuantity"
								:max-quantity="vm.BaseViewModel.MaxGlobalBasketQuantity"
								:maxTotalQuantityOfReimbursableItemsAllowed="vm.BaseViewModel?.MaxReimbursableItemsQuantity || 0"
								@close="close" />
					<sticky-summary
						:hide-total="vm.BaseViewModel.HideTotal"
						:labels="labels"
						:checkout-url="checkoutUrl"
						:hide-all-prices="vm.BaseViewModel.HideAllPrices" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapMutations } from 'vuex';
	import Events from 'shared/settings/events';
	import GlobalBasket from './globalBasket.vue';
	import StickySummary from './stickySummary.vue';
	import { track_view_basket } from 'shared/services/tracking/basketTrackingService';
	import { DsIconButton } from '@coloplast/design-system';
	import useModalFocusTrap from 'shared/composables/focusTrap';

	export default {
		components: {
			GlobalBasket,
			StickySummary,
			DsIconButton,
		},
		props: {
			vm: {
				type: Object,
				required: true,
			},
			checkoutUrl: {
				type: String,
				required: true,
			},
			emptyBasketUrl: {
				type: String,
				required: true,
			},
			browseMoreUrl: {
				type: String,
				required: true,
			},
		},
		provide() {
			return {
				myProductsCtaLinkText: this.vm?.BaseViewModel?.MyProductsCtaLinkText,
				myProductsCtaLinkUrl: this.vm?.BaseViewModel?.MyProductsCtaLinkUrl,
				myProductsCtaIsEnabled: this.vm?.BaseViewModel?.IsMyProductsCtaEnabled,
			};
		},
		methods: {
			...mapActions({
				fetchBasket: 'fetchBasket',
			}),
			...mapMutations({
				setBasketApiCulture: 'setBasketApiCulture',
				setCheckOutURL: 'setCheckOutURL',
				setBrowseMoreURL: 'setBrowseMoreURL',
				setBasketDebounceTime: 'setBasketDebounceTime',
				setFlyOutBasketActive: 'setFlyOutBasketActive',
				setE1TaxCalculate: 'setE1TaxCalculate',
				setPageId: 'setPageId',
			}),
			close() {
				this.active = false;
			},
			handleEmptyBasketRedirect() {
				if (!this.isCheckoutFlow) return;

				this.close();
				window.location.assign(this.emptyBasketUrl);
			},
			checkUrlQuery() {
				if (window.location.search.includes('displayBasket=true')) {
					const event = new CustomEvent(
						Events.ACTIVATE_FLY_OUT_BASKET,
						{}
					);
					document.dispatchEvent(event);
				}
			}
		},
		watch: {
			async active() {
				if (this.active) {
					this.setFlyOutBasketActive(true);
					let response = null;
					try {
						response = await this.fetchBasket();
						document.body.classList.add('h-overflow-y-hidden');

						const url = new URL(window.location);
						const params = new URLSearchParams(url.search);
						const isQuickLink = params.has('quickLink') ? 'yes' : 'no';
						track_view_basket(response, { quick_link: isQuickLink });

						params.delete('quickLink');
						window.history.replaceState(null, '', window.location.pathname + '?' + params.toString());
					}
					catch (err) {
						console.error(err);
					}
				} else {
					document.body.classList.remove('h-overflow-y-hidden');
					this.setFlyOutBasketActive(false);
				}
			},
			'basket.isEmpty'(newValue) {
				if (newValue) {
					// close and redirect on checkout flow if basket is empty
					this.handleEmptyBasketRedirect();
				}
			},
		},
		data() {
			return {
				active: false,
				restoreFocus: null,
				value: 0,
			};
		},
		computed: {
			isCheckoutFlow() {
				const wrapper = document.querySelector('[data-checkout-item]');

				return (
					wrapper !== null &&
					!wrapper.hasAttribute('data-checkout-confirmation')
				);
			},
			...mapGetters({
				culture: 'getCulture',
				basket: 'getBasket',
				getProductsFromAccount: 'getProductsData',
				currency: 'getCurrency',
				numberOfBasketItems: 'getBasketItemCount',
			}),
			labels() {
				const vm = this.vm.BaseViewModel;

				return {
					packLabel: vm.Pack,
					packPreposition: vm.PackPreposition,
					packOfLabel: vm.PackOf,
					packsOfLabel: vm.PacksOf,
					quantityLabel: vm.Quantity,
					linePriceTotalLabel: vm.LinePriceTotal,
					subTotalLabel: vm.SubTotal,
					shippingLabel: vm.Shipping,
					shippingInformationTooltip: vm.ShippingInformationTooltip,
					totalLabel: vm.Total,
					checkout: vm.CheckoutText,
					emptyBasketText: vm.EmptyBasketText,
					emptyBasketLinkText: vm.EmptyBasketLinkText,
					colorLabel: vm.ColorLabel,
					headline: vm.Heading,
					item: vm.SingleItem,
					items: vm.MultipleItems,
					salesTaxIncludedLabel: vm.SalesTaxIncluded,
					salesTaxTotalLabel: vm.SalesTaxTotal,
					freeShippingLabel: vm.FreeShipping,
					browseMoreText: vm.BrowseMoreText,
					samplePackLabel: vm.SamplePackText,
					freeSampleText: vm.FreeSampleText,
					reimbursedProductLabel: vm.ReimbursedProductLabel,
					complimentaryProductLabel: vm.ComplimentaryProductLabel,
					NonRegularProducts: {
						AddMyProductsLabel: vm.NonRegularProductsAddMyProductsLabel,
						LessProductsLabel: vm.NonRegularProductsLessProductsLabel,
						MoreProductsLabel: vm.NonRegularProductsMoreProductsLabel,
						SectionHeading: vm.NonRegularProductsSectionHeading,
					},
					couponDiscountTotal: vm.CouponDiscountTotal,
					couponHeaderLabel: vm.CouponHeaderLabel,
					couponPlaceholderText: vm.CouponPlaceholderText,
					couponValidationErrorMessage: vm.CouponValidationErrorMessage,
					couponAppliedHeaderLabel: vm.CouponAppliedHeaderLabel,
					vatAmountLabel: vm.VatAmountLabel,
					vatMessage: this.basket?.isTaxValidated
						? vm.AdjustedVatMessage
						: vm.NotAdjustedVatMessage,

					adjustedVatMessage: vm.AdjustedVatMessage,
					notAdjustedVatMessage: vm.NotAdjustedVatMessage,

					vatIsNotIncludedLabel: vm.VatIsNotIncludedLabel,
					payLaterNotificationText: vm.OfflinePaymentNotificationLabel,
					cuttingService: {
						activeLabel: vm.ActiveCuttingServiceLabel,
						checkboxLabel: vm.UseCuttingServiceCheckboxLabel,
						learnMoreLabel: vm.LearnMoreLabel,
						newOrderLabel: vm.NewCuttingServiceOrderLabel,
						dropdownLabel: vm.OrderTemplatesDropdownLabel,
						summary: vm.CuttingServiceSummaryLabel,
					},
					checkoutButtonLoadingLabel: vm.CheckoutButtonLoadingLabel,
					productKitPriceLabel: vm.ProductKitPriceLabel,
					hideProductKitComponentsLabel: vm.HideProductKitComponentsLabel,
					showProductKitComponentsLabel: vm.ShowProductKitComponentsLabel,
					accessibility: {
						accessibilityProductLinkLabel: vm.AccessibilityProductLinkLabel,
						quantitySelectorLabel: vm.AccessibilityQuantitySelectorLabel,
						increaseQuantityLabel: vm.AccessibilityIncreaseQuantityLabel,
						decreaseQuantityLabel: vm.AccessibilityDecreaseQuantityLabel,
						removeItem: vm.AccessibilityRemoveItemLabel,
						selectCuttingService: vm.AccessibilitySelectCuttingServiceLabel,
						toggleBasketSummary: vm.AccessibilityShowBasketSummaryLabel,
						applyCouponCode: vm.AccessibilityApplyCouponCodeLabel,
						removeCouponCode: vm.AccessibilityRemoveCouponCodeLabel,
					},
					e1FixedTaxRateInformationalMessage: vm.E1FixedTaxRateInformationalMessage,
					totalInclVat: vm.TotalInclVat,
					maxLineItemOrderingQuantityReachedMessage: vm.MaxLineItemOrderingQuantityReachedMessage,
					maxReimbursableItemsQuantityReachedMessage: vm.MaxReimbursableItemsQuantityReachedMessage,
				};
			},

			basketIsEmpty() {
				return this.numberOfBasketItems === 0;
			},
		},
		mounted() {
			this.setBasketApiCulture(this.culture);
			this.setCheckOutURL(this.checkoutUrl);
			this.setBrowseMoreURL(this.browseMoreUrl);
			this.setBasketDebounceTime(this.vm.BaseViewModel.BasketBounceTime);

			document.addEventListener(Events.ACTIVATE_FLY_OUT_BASKET, () => {
				this.active = true;
				this.restoreFocus = useModalFocusTrap(this.$refs.root, this.close, 'not_used', true).restoreFocus;
			});

			this.checkUrlQuery();

			if ( this.vm.BaseViewModel.IsE1TaxCalculationEnabled ) {
				const vm = this.vm.BaseViewModel;
				this.setE1TaxCalculate(
					{
						apiUrl: vm.TaxUrl,
						taxParams: {},
						isNeedE1TaxCalculate: vm.IsE1TaxCalculationEnabled,
						appliedInCheckoutText: vm.TaxInformationalMessageText,
						isE1TaxLoading: false,
					}
				)
				this.setPageId(vm?.PageId)
			}

		},
	};
</script>
